<div id="map-political" class="slide">
  <header class="header">
    <h1 class="title">{{content.landing.sections[0].title}}</h1>
  </header>

  <NavTools content="{{content}}" which="damages" slide="12" on:soundChange="fire('soundChange', { state: event.state } )" on:goToEvent="goToEvent()"/>  

  <nav class="navigation">
    <div class="navigation-container">
      <div class="navigation-buttons">
        {{#each content.political as year, i}}
        <button class="navigation-button {{(year.year === selectedYear.year) ? 'selected' : ''}}" on:click="select(i + 3)" type="button">{{year.year}}</button>
        {{/each}}
      </div>
      <AudioPlayer src="/syria/assets/sounds/political-voice-{{lang}}.mp3" spriteId={{spriteId}} sprite="{{(lang === 'it') ? sprite_it : sprite_en}}" slide="{{slide}}" on="3" off="10" on:spriteReached="selectByYear(event.item)" bind:audioPlay=audioPlay/>
    </div>
  </nav>

  <div class="content">
    {{#if selectedYear}}
    <div class="main-text">{{{marked(selectedYear.text[0])}}}</div>
    <img class="map" transition:fade src="/syria/assets/images/map-{{selectedYear.year}}-{{lang}}.svg">
    <div class="aside">
       <PoliticalMapLegends content="{{content}}" /> 
    </div>
    {{/if}}
  </div>

</div>

<style>
  .slide {
    justify-content: flex-start;
  }

  .header {
    text-align: left;
    display: block;
    width: 25%;
    max-width: 16rem;
    position: absolute;
    top: 3rem;
    left: 3rem;
  }

  .title {
    font-size: 1.1rem;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
  }

  .navigation-container {
    width: 40%;
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  .navigation-button {
    font-family: var(--sans);
    color: var(--yellow-white);
    font-size: 1.2rem;
    opacity: 0.5;
    padding-top: .25rem;
    transition: opacity .25s, border-color .5s;
    border-top: 4px solid transparent;
  }

  .navigation-button.selected {
    opacity: 1;
    border-color: var(--yellow-white);
  }

  .navigation-button:first-of-type {
    margin-left: 0;
  }

  .navigation-button:last-of-type {
    margin-right: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .main-text {
    width: calc(20% - 1.5rem);
    padding-right: 1.5rem;
  }

  .main-text p {
    font-family: var(--sans);
    font-weight: 200;
    letter-spacing: .05rem;
    line-height: 1.4rem;
    font-size: .925rem;

    margin: .8rem 0;
  }

  .main-text p:first-of-type {
    margin-top: 0;
  }

  .aside {
    align-self: flex-end;
    width: 18%;
  }

  .map {
    display: block;
    width: 60%;
    max-height: 65vh;
  }

  @media (max-height: 920px) {
    .main-text {
      padding-right: .5rem;
      width: calc(25% - .5rem);
    }  

    .main-text p {
      line-height: 1rem;
      margin: .3rem 0;
    }
  }
</style>

<script>
  import { fade } from 'svelte-transitions';
  import marked from 'marked';

  import { router, scroller } from '../js/lib/scroll-router';

  // Components
  import AudioPlayer from './AudioPlayer.html';
  import NavTools from './NavTools.html';
  import PoliticalMapLegends from './PoliticalMapLegends.html';

  export default {
    helpers: {
      marked
    },
    oncreate() {
      router(slide => {
        this.set({ slide });

        switch (slide) {
          case 3:
            this.set({ 
              selectedYear: this.get('content').political[0],
              spriteId: this.get('content').political[0].year
            });
            break;
          case 4:
            this.set({ 
              selectedYear: this.get('content').political[1],
              spriteId: this.get('content').political[1].year
            });
            break;
          case 5:
            this.set({ 
              selectedYear: this.get('content').political[2],
              spriteId: this.get('content').political[2].year
            });
            break;
          case 6:
            this.set({ 
              selectedYear: this.get('content').political[3], 
              spriteId: this.get('content').political[3].year
            });
            break;
          case 7:
            this.set({ 
              selectedYear: this.get('content').political[4],
              spriteId: this.get('content').political[4].year
            });
            break;
          case 8:
            this.set({ 
              selectedYear: this.get('content').political[5], 
              spriteId: this.get('content').political[5].year
            });
            break;
          case 9:
            this.set({ 
              selectedYear: this.get('content').political[6],
              spriteId: this.get('content').political[6].year
            });
            break;
        }
      });
    },
    methods: {
      select(slide, year) {
        scroller(slide);
      },
      selectByYear(year) {
        switch(year) {
          case '2012':
            if (this.get('slide') !== 4)
              scroller(4);
            break;
          case '2013':
            if (this.get('slide') !== 5)
              scroller(5);
            break;
          case '2014':
            if (this.get('slide') !== 6)
              scroller(6);
            break;
          case '2015':
            if (this.get('slide') !== 7)
              scroller(7);
            break;
          case '2016':
            if (this.get('slide') !== 8)
              scroller(8);
            break;
          case '2017':
            if (this.get('slide') !== 9)
              scroller(9);
            break;
        }
      },
      goToEvent() {
        this.set({'audioPlay': false});
      }
    },
    transitions: { fade },
    components: {
      AudioPlayer,
      NavTools,
      PoliticalMapLegends
    },
    computed: {
      selectedYear: content => content.political[0]
    },
    data: () => {
      return {
        audioPlay: true,
        spriteId: 2011,
        slide: 1,
        sprite_it: {
          2011: [0, 32],
          2012: [32, 63.34],
          2013: [63.34, 99.27],
          2014: [99.27, 137.40],
          2015: [137.40, 168.77],
          2016: [168.77, 199.54],
          2017: [199.54, 231.7698324611896]
        },
        sprite_en: {
          2011: [0, 72.43],
          2012: [72.43, 142.97],
          2013: [142.97, 219.5],
          2014: [219.5, 290.98],
          2015: [290.98, 362.25],
          2016: [362.25, 426],
          2017: [426, 494.8]
        }
      }
    }
  };

</script>
