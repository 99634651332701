<div class="audioplayer">
  <button class="button {{state}}" on:click="play()"></button>
  <div ref:timeline class="timeline">
    <div ref:playhead class="playhead"></div>
    <div ref:progress class="progress"></div>
  </div>
</div>

<style>
  .audioplayer {
    display: flex;
    width: 100%;
    position: relative;
  }

  .timeline {
    cursor: pointer;
    width: 100%;
    height: 10px;
    border: 1px solid var(--yellow-white);
    background-color: transparent;
    position: relative;
  }

  .playhead {
    cursor: pointer;
    width: 16px;
    height: 10px;
    background-color: var(--yellow-white);
  }

  .progress {
    width: 0;
    height: 10px;
    left: 0;
    top: 0;
    background-color: var(--yellow-white);
    opacity: 0.3;
    position: absolute;
  }

  .button {
    height: 12px;
    width: 12px;
    margin-right: 12px;
    border: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: -24px;
  }

  .button.playing {
    background-image: url('../../assets/images/player-play.svg');
  }

  .button.paused {
    background-image: url('../../assets/images/player-pause.svg');
  }
</style>

<script>
  import { Howl } from 'howler/dist/howler';

  export default {
    oncreate() {
      const timeline = this.refs.timeline;
      const playhead = this.refs.playhead;
      const progress = this.refs.progress;
      const timelineWidth = timeline.offsetWidth - playhead.offsetWidth - 2;
      let updateProgressAnimationId;
      let duration;
      let onplayhead = false;

      let component = this;

      let audio = new Howl({
        src: this.get('src'),
        onload: function () {
          duration = audio.duration();
        },
        onplay: function () {
          updateProgressAnimationId = requestAnimationFrame(updateProgress);
        },
        onend: function() {
          cancelAnimationFrame(updateProgressAnimationId);
          component.set({ state: 'playing' });
          // playhead.style.marginLeft = 0;
        },
        onpause: function () {
          cancelAnimationFrame(updateProgressAnimationId);
        },
        onstop: function () {
          cancelAnimationFrame(updateProgressAnimationId);
        },
        onseek: function (event) {
          drawProgress(this.seek(), duration, playhead, progress, timelineWidth);
          // cancelAnimationFrame(updateProgressAnimationId);
        }
      });

      this.audio = audio;

      let audioOn = parseInt(this.get('on'));
      let audioOff = parseInt(this.get('off'));

      // Controls audio on/off 
      component.observe('slide', slide => {

        if (slide && slide == audioOn) {
          if (!this.audio.playing(this.soundId)) {
            this.soundId = audio.play();
            this.set({ state: 'paused' })
          }
        } else if (slide == audioOff - 1) {
          if (this.audio.playing(this.soundId)) {
            this.set({ state: 'paused' })
          } else {
            this.set({ state: 'playing' })
          }
        } else if (slide && slide == audioOff || slide == audioOn - 1) {
          audio.pause();
          this.set({ state: 'playing' });
        }
      });

      // Controls audio with slides
      component.observe('spriteId', spriteId => {
        if (spriteId && this.get('sprite')) {
          let time = this.get('sprite')[spriteId][0];

          audio.seek(time);
          audio.fade(1, 0, 1000);
          audio.fade(0, 1, 1000);
        }
      });
      
      // Point to seek
      timeline.addEventListener('click', (event) => {
        movePlayhead(event, timeline, playhead, timelineWidth);
        audio.seek(duration * clickPercent(event, timeline, timelineWidth));
      });

      // Drag to seek
      // playhead.addEventListener('mousedown', (event) => mouseDown(event, audio, timeline, playhead, timelineWidth));
      // window.addEventListener('mouseup', (event) => mouseUp(event, audio, playhead, timelineWidth));

      function updateProgress() {
        let seek = audio.seek();
        drawProgress(seek, duration, playhead, progress, timelineWidth);
        updateProgressAnimationId = requestAnimationFrame(updateProgress);

        if (component.get('sprite')) {
          for (let item in component.get('sprite')) {
            if (seek > component.get('sprite')[item][0] && seek < component.get('sprite')[item][0] + 0.01)
              component.fire('spriteReached', { item });
          }
        }
  
      }

      component.observe('audioPlay', audioPlay => {
        this.set({'audioPlay': true});

        if (this.audio.playing(this.soundId)) {
          this.audio.pause();
          if (this.set({ state: 'playing' }))
            this.set({ state: 'paused' })
        }

      })
    },
    methods: {
      play() {
        if (!this.audio.playing(this.soundId)) {
          this.soundId = this.audio.play();
          if (this.set({ state: 'paused' }))
            this.set({ state: 'playing' })
        } else {
          this.audio.pause();
          if (this.set({ state: 'playing' }))
            this.set({ state: 'paused' })
        }
      }
    },
    data() {
      return {
        state: 'playing'
      }
    }
  }

  function drawProgress(seek, duration, playhead, progressEl, timelineWidth) {
    let progress = timelineWidth * (seek / duration);
    playhead.style.marginLeft = `${progress}px`;
    progressEl.style.width = `${progress}px`;
  }

  function movePlayhead(event, timeline, playhead, timelineWidth) {
    let newMargLeft = event.clientX - getPosition(timeline);

    if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
      playhead.style.marginLeft = newMargLeft + "px";
    }
    if (newMargLeft < 0) {
      playhead.style.marginLeft = "0px";
    }
    if (newMargLeft > timelineWidth) {
      playhead.style.marginLeft = timelineWidth + "px";
    }
  }

  function mouseUp(event, audio, playhead, timelineWidth) {
    if (onplayhead === true) {
      moveplayhead(event, timeline, playhead, timelineWidth);
      window.removeEventListener('mousemove', moveplayhead);

      let to = duration * clickPercent(event, timeline, timelineWidth);

      if (to >= duration) {
        audio.seek(0);
      } else {
        audio.seek(to);
      }

      updateProgressAnimationId = requestAnimationFrame(updateProgress);
    }

    onplayhead = false;
  }

  function mouseDown(event, audio, timeline, playhead, timelineWidth) {
    onplayhead = true;

    cancelAnimationFrame(updateProgressAnimationId);
    window.addEventListener('mousemove', (event) => moveplayhead(event, timeline, playhead, timelineWidth));
  }

  function clickPercent(event, timeline, timelineWidth) {
    return (event.clientX - getPosition(timeline)) / timelineWidth;
  }

  function getPosition(el) {
    return el.getBoundingClientRect().left;
  }

</script>
