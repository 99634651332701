<div id="cover" class="slide" style="background-image:url('{{content.cover[slide].img_url}}')">
  <!-- <h1 class="title">{{content.landing.title}}</h1> -->
  <h2 class="subtitle">{{content.landing.sections[slide].title}}</h2>
</div>

<style>
  .title {
    text-transform: uppercase;
    letter-spacing: 5px;
    text-align: center;
    width: 80%;
    font-size: 2rem;
    text-align: center;
    margin: 0;
  }

  .subtitle {
    font-weight: 800;
    font-size: 2rem;
    text-align: center;
    max-width: 30rem;
    line-height: 1.4em;
  }
</style>
