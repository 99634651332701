<div id="map-damages" class="slide">

  <header class="header">
    <h1 class="title">{{content.landing.sections[1].title}}</h1>
  </header>

  <NavTools content="{{content}}" which="political" slide="3" on:goToEvent="goToEvent()"/>

  <nav class="player">
    <div class="player-container">
      <AudioPlayer src="/syria/assets/sounds/damages-voice-{{lang}}.mp3" slide="{{slide}}" on="12" off="15" bind:audioPlay=audioPlay/>
    </div>
  </nav>

  <div class="content">
    <div class="map-filters" id="map-filters">

      <div id="map-filters-container">
        <div class="map-filters-group">
          <h3 class="map-filters-title" id="map-filters-type-title">{{content.damages.filter_type_title}}</h3>
          <nav class="map-filters-buttons" id="map-filters-by-type"></nav>
        </div>

        <div class="map-filters-group" id="map-filters-by-unesco-left">
          <h3 class="map-filters-title" data-type="map-filters-unesco-title"></h3>
          <nav class="map-filters-buttons" data-type="map-filters-by-unesco"></nav>
        </div>
      </div>

    </div>

    <div class="map" id="map-container">
      <nav class="year-filters" id="year-filters">
        <button type="button" data-el="damage-selector-by-year" data-year="2014">{{content.damages.years[0].label}}</button>
        <button type="button" data-el="damage-selector-by-year" data-year="2015">{{content.damages.years[1].label}}</button>
        <button type="button" data-el="damage-selector-by-year" data-year="2016">{{content.damages.years[2].label}}</button>
        <button type="button" data-el="damage-selector-by-year" data-year="2017">{{content.damages.years[3].label}}</button>
      </nav>

      <div class="map-vis">
        <svg class="map-svg" id="main-map"></svg>
      </div>
    </div>

    <aside class="map-info" id="map-info">
      <!-- <img class="map-info-terrain" src="/assets/images/syria-map-mercator.svg"> -->
      <svg class="map-info-svg" id="info-svg-map"></svg>
      <div class="map-info-container" data-el="info-container"></div>

      <div class="map-filters-group" id="map-filters-by-unesco-right">
        <h3 class="map-filters-title" data-type="map-filters-unesco-title"></h3>
        <nav class="map-filters-buttons" data-type="map-filters-by-unesco"></nav>
      </div>
    </aside>
  </div>

  <div class="unesco-caption-container" id="unesco-caption-container">
    <div class="unesco-caption unesco-caption-left">

      <div class="unesco-group">
        <div class="unesco-number unesco-number-icon unesco-number-icon-generic">
          {{content.damages.unesco_captions[0].number}}
        </div>
        <div class="unesco-text">
          {{content.damages.unesco_captions[0].text}}
        </div>
      </div>

      <div class="unesco-group">
        <div class="unesco-number unesco-number-icon unesco-number-icon-unesco">
          {{content.damages.unesco_captions[1].number}}
        </div>
        <div class="unesco-text">
          {{content.damages.unesco_captions[1].text}}
        </div>
      </div>

    </div>

    <div class="unesco-caption unesco-caption-right">

      <div class="unesco-group">
        <div class="unesco-number">
          {{content.damages.unesco_captions[2].number}}
        </div>
        <div class="unesco-text">
          {{content.damages.unesco_captions[2].text}}
        </div>
      </div>

      <div class="unesco-group">
        <div class="unesco-number">
          {{content.damages.unesco_captions[3].number}}
        </div>
        <div class="unesco-text">
          {{content.damages.unesco_captions[3].text}}
        </div>
      </div>

    </div>
  </div>

  <div class="map-casualties" id="map-casualities" data-el="casualties-values">
    <h3 class="casualties-title" id="map-casualties-title">{{content.damages.casualties_title}}</h3>
    <div class="casualities-values" id="casualities-values">
      <div class="casualities-values-container" id="casualities-values-container">
        <div class="casualties-value" data-year="2014"></div>
        <div class="casualties-value" data-year="2015"></div>
        <div class="casualties-value" data-year="2016"></div>
        <div class="casualties-value" data-year="2017"></div>
      </div>
    </div>
    <div class="casualities-extras"></div>
  </div>

</div>

<style>
  .slide {
    width: calc(100% - 6rem);
    display: initial;
  }

  .title {
    font-size: 1.1rem;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .header {
    text-align: left;
    display: block;
    width: 25%;
    max-width: 15rem;
    position: absolute;
    top: 3rem;
    left: 3rem;
  }

  .player {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 1.5rem 0;
  }

  .player-container {
    width: 40%;
  }

  .content {
    /* margin-top: 2rem; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .unesco-caption-container {
    position: absolute;
    height: calc(100% - 6rem);
    width: 100%;
    top: 3rem;
    left: 0;
    pointer-events: none;
  }

  .unesco-caption {
    position: absolute;
    width: 18%;
    top: 55%;
    transform: translateY(-55%);
  }

  .unesco-caption-left {
    left: 3rem;
  }

  .unesco-caption-right {
    right: 3rem;
  }

  .unesco-group {
    margin-bottom: 1.5rem;
  }

  .unesco-number {
    font-size: 2.5rem;
    margin-bottom: .25rem;
  }

  .unesco-number-icon::after {
    content: '';
    margin-left: .75rem;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
  }

  .unesco-number-icon-unesco::after {
    background-image:  url('/syria/assets/images/icon-unesco.svg');
  }

  .unesco-number-icon-generic::after {
    background-image:  url('/syria/assets/images/icon-others.svg');
  }

  .map-filters {
    width: 18%;
    margin-top: 4rem;
  }

  .map-filters-group {
    margin-bottom: 2rem;
  }

  .map-filters-container {
    opacity: 0;
  }

  .map-filters-title {
    font-size: 1rem;
    margin-top: 0;
    margin-top: 1rem;
    margin-bottom: .6rem;
  }

  .map-filters-buttons div {
    font-family: var(--sans);
    color: var(--yellow-white);
    font-weight: 300;
    letter-spacing: .05em;
    width: 100%;
    text-align: left;
    margin-bottom: .2em;
    cursor: pointer;

    display: flex;
    align-content: middle;
  }

  .map-filters-buttons div:hover {
    text-decoration: underline;
  }

  .map-filters-buttons div {
    margin-bottom: .5rem;
  }

  .map-filters-buttons div img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .map-filters-buttons div p {
    margin: 0;
    display: inline;
    vertical-align: middle;
    line-height: 20px;
    font-weight: 200;
  }

  .map-filters-buttons .is-active {
    text-decoration: underline;
    font-weight: 400;
  }

  #map-filters-by-unesco-right {
    display:  none;
  }

  .map {
    width: 50%;
    height: 85%;
    position: relative;
  }

  .map-vis {
    display: flex;
    align-items: center;
    justify-content: center;

    left: 0;
    top: 0; 
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .map-svg {
    /* position: absolute;
    left: 0; */
    display: inline-block;
    width: 100%;
    height: 50%;
  }

  .map-svg .map-svg-item:not([data-sel="false"]) {
    cursor: pointer;
    box-shadow: 0 0 10px white;
  }

  .map-svg .map-svg-item:not([data-sel="true"]) {
    pointer-events: none;
  }

  /* Years filters  */

  .year-filters {
    width: 100%;
    z-index: 100;
    position: relative;
    opacity: 0;
  }

  .year-filters button {
    font-family: var(--sans);
    color: var(--yellow-white);
    font-weight: 400;
    letter-spacing: .05em;
    width: 22%;
    margin-right: 2.8%;
    text-align: center;
    font-size: 1.3rem;
  }

  .year-filters button:last-of-type {
    margin-right: 0;
  }

  /* Map casualities  */

  .map-casualties {
    position: absolute;
    color: var(--yellow-white);
    bottom: 1.5rem;
    left: 3rem;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 6rem);

    opacity: 0;
    z-index: -1;
  }

  .casualties-title {
    width: 18%;
    margin: 0;
    font-family: var(--sans);
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .05em;
  }

  .casualities-values {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .casualties-value {
    display: inline-block;
    font-family: var(--sans);
    color: var(--yellow-white);
    font-weight: 400;
    letter-spacing: .05em;
    width: 22%;
    margin-right: 2.8%;
    text-align: center;
    font-size: 1.3rem;
  }

  .casualties-value:last-of-type {
    margin-right: 0;
  }

  .casualities-extras {
    width: 18%;
  }

  /* Mini-map info  */

  .map-info {
    width: 18%;
    height: 85.5%;
    margin-top: 4rem;
    position: relative;
    opacity: 0;
  }

  .map-info-container {
    margin-bottom: .8rem;
  }

  .map-info-name {
    font-size: .825rem;
    margin-bottom: .25rem;
  }

  .map-info-reason,
  .map-info-coordinates,
  .map-info-place,
  .map-info-type {
    font-family: var(--sans);
    font-size: 1rem;
    font-weight: 200;
    margin-bottom: .15rem;
  }

  .map-info-coordinates,
  .map-info-type {
    margin-bottom: .3rem;
  }

  .map-info-terrain {
    content: '';
    position: absolute;

    z-index: -1;

    top: 1.2%;
    left: 4%;

    width: 93%;
  }

  .map-info-svg {
    overflow: visible;
    height: 0;
    padding-bottom: 100%;
  }

  @media (max-height: 920px) {

    /*.casualties-title {
      width: 15%;
    }

    .map-filters {
      width: 15%;
    }*/

    .map-info-svg {
      padding-bottom: 70%;
    }
  
    .map-filters-buttons div {
      margin-bottom: .35rem;
    }

    .map,
    .casualities-values {
      width: 63%;
    }

    #map-filters-by-unesco-left {
      display: none;
    }

    #map-filters-by-unesco-right {
      display:  block;
    }
  }
</style>

<script>
  import * as d3 from 'd3';
  import TweenMax from 'gsap';
  import 'gsap/src/uncompressed/plugins/ScrollToPlugin.js';
  import { router } from '../js/lib/scroll-router';

  // Components
  import AudioPlayer from './AudioPlayer.html';
  import NavTools from './NavTools.html';

  export default {
    oncreate() {
      let selection = d3.select('#map-damages');
      let contentData = this.get('content');
      let svg = selection.select('#main-map');

      let containerWidth = parseInt(d3.select('#map-container').node().clientWidth);
      let containerHeight = parseInt(d3.select('#map-container').node().clientHeight);

      const vizRatio = 1.11;

      let liveWidth;
      let liveHeight;

      if ( (containerWidth / containerHeight) > vizRatio ) {
        // Get Width from Height
        liveHeight = containerHeight;
        liveWidth = parseInt(liveHeight * vizRatio);
      } else {
        // Get Height from Width
        liveWidth = containerWidth;
        liveHeight = parseInt(liveWidth / vizRatio);
      }

      let margin = { top: 20, right: 0, bottom: 20, left: 0 };
      let width = liveWidth - margin.left - margin.right;
      let height = liveHeight - margin.top - margin.bottom;

      // Set svg and nav dimensions
      svg.style('overflow', 'visible')
        .style('width', width + 'px')
        .style('height', height + 'px');

      d3.select('#year-filters')
        .style('width', width + 'px')
        .style('margin', `0px ${ (containerWidth - width) / 2 }px`);

      d3.select('#casualities-values')
          .style('width', parseInt(d3.select('#map-container').node().clientWidth) + 'px')

      d3.select('#casualities-values-container')
        .style('width', width + 'px')
        // .style('margin', `0px ${ (containerWidth - width) / 2 }px`);


      d3.queue()
        .defer(d3.json, '/syria/data/syria.geo.json')
        .defer(d3.json, '/syria/data/syria-damages.geo.json')
        .await((error, syria, damages) => {
          if (error) throw error;

          // let places = d3.map(damages.features, function(d) { return d.properties.place; });
          drawContent(selection, contentData);
          drawMap(selection, svg, width, height, syria, damages);
          drawInfoMap(selection, syria);

          let points = svg.selectAll('image[data-year]');
          let initialPoints = getInitialPoints(points);

          // Internal scroll router
          router(slide => {
            this.set({ slide });

            switch (slide) {
              case 12:
                selection
                  .select('#damage-syria-terrain')
                  .transition()
                  .duration(1000)
                  .style('opacity', 1);

                // UI transitions
                TweenMax.to('.unesco-caption', 0.5, { autoAlpha: 1 });
                TweenMax.to('#unesco-caption-container', 1, { display: 'block' });

                TweenMax.to('#map-info', 0.5, { autoAlpha: 0 });
                TweenMax.to('#year-filters', 0.5, { autoAlpha: 0 });
                TweenMax.to('#map-filters-container', 0.5, { autoAlpha: 0 });
                TweenMax.to('#map-casualities', 0.5, { autoAlpha: 0 });


                repositionPoints(svg, initialPoints);
                break;

              case 14:
                selection
                  .select('#damage-syria-terrain')
                  .transition()
                  .duration(1000)
                  .style('opacity', 0);

                // UI transitions
                TweenMax.to('.unesco-caption', 0.5, { autoAlpha: 0 });
                TweenMax.to('#unesco-caption-container', 1, { display: 'none' });

                TweenMax.to('#map-info', 0.5, { autoAlpha: 1 });
                TweenMax.to('#year-filters', 0.5, { autoAlpha: 1 });
                TweenMax.to('#map-filters-container', 0.5, { autoAlpha: 1 });
                TweenMax.to('#map-casualities', 0.5, { autoAlpha: 1 });
 
                clusterPoints(svg, selection, width, height);
                break;
            }
          });

        });
    },
    methods: {
      goToEvent() {
        this.set({ 'audioPlay': false });
      }
    },
    components: {
      AudioPlayer,
      NavTools
    }
  };

  function drawContent(selection, content) {
    let container = selection.select('#map-filters');
    let filterType = content.damages.filter_type;
    let filterUnesco = content.damages.filter_unesco;

    // Filters
    let unescoTitles = d3.selectAll('[data-type="map-filters-unesco-title"]');

    unescoTitles.nodes().forEach(title => {
      d3.select(title).text(content.damages.filter_unesco_title);
    })
      

    let buttonType = container
      .select('#map-filters-by-type')
      .selectAll('div')
      .data(filterType)
      .enter()
      .append('div')
      .attr('data-el', 'damage-selector-by-type')
      .attr('data-damage', d => slug(d.name));

    buttonType
      .append('img')
      .attr('src', d => `/syria/assets/images/icon-${slug(d.name)}.svg`);

    buttonType
      .append('p')
      .text(d => d.label);

    let unescoButtons = d3.selectAll('[data-type="map-filters-by-unesco"]');

    unescoButtons.nodes().forEach(buttons => {
      d3.select(buttons)
        .selectAll('div')
        .data(filterUnesco)
        .enter()
        .append('div')
        .attr('data-el', 'damage-selector-by-unesco')
        .attr('data-damage', d => d.name)
        .text(d => d.label);
    })
  }

  function repositionPoints(svg, initialPoints) {
    moveToMap(svg, initialPoints);
  }

  function clusterPoints(svg, mapContainer, width, height) {

    let maxWidth = 1100;
    let minWidth = 500;

    let scalePointWidth = d3.scaleSqrt()
      .exponent(3)
      .domain([minWidth, maxWidth])
      .range([1, 5])
      .clamp(true);

    let scaleGutter = d3.scaleSqrt()
      .exponent(3)
      .domain([minWidth, maxWidth])
      .range([11, 15])
      .clamp(true);

    let scaleGrid = d3.scaleSqrt()
      .exponent(3)
      .domain([minWidth, maxWidth])
      .range([10, 50])
      .clamp(true);

    let pointWidth = scalePointWidth(width);
    let gutter = scaleGutter(width);
    let gridWidth = scaleGrid(width);
    let viewportHeight = window.innerHeight;
    let mapDamagesHeight = d3.select('#main-map').node().getBoundingClientRect().height;

    let clusterHeight = ((viewportHeight - mapDamagesHeight) / 2) + mapDamagesHeight - 100;

    // d3.select('#map-casualities').style('bottom', `${(( viewportHeight * .35 ) - 350 )}px`)

    moveToGrid(svg, '2014', { gridWidth, pointWidth, gutter, offset: { x: 0, y: clusterHeight } });
    moveToGrid(svg, '2015', { gridWidth, pointWidth, gutter, offset: { x: width / 3.85, y: clusterHeight } });
    moveToGrid(svg, '2016', { gridWidth, pointWidth, gutter, offset: { x: (width / 3.85) * 2, y: clusterHeight } });
    moveToGrid(svg, '2017', { gridWidth, pointWidth, gutter, offset: { x: (width / 3.85) * 3, y: clusterHeight } });

    filterMap(svg, mapContainer);
  }

  function filterMap(svg, selection) {
    const damageSelectorsByType = selection.selectAll('[data-el="damage-selector-by-type"]');
    const damageSelectorsByYear = selection.selectAll('[data-el="damage-selector-by-year"]');
    const damageSelectorsByUnesco = selection.selectAll('[data-el="damage-selector-by-unesco"]');

    damageSelectorsByType.on('click', selectDamageByType);
    damageSelectorsByYear.on('click', selectDamageByYear);
    damageSelectorsByUnesco.on('click', selectDamageByUnesco);

    selectDamageByUnesco();

    function selectDamageByType() {
      let damageType = this.dataset.damage;

      let itemsToDownplay = svg.selectAll(`image:not([data-type="${damageType}"])`);
      let itemsToHighlight = svg.selectAll(`image[data-type="${damageType}"]`);

      if (d3.select(this).classed('is-active')) {
        itemsToHighlight = svg.selectAll('.map-svg-item');
      }

      changeButtonStatus(this);
      countByYear(itemsToHighlight);
      changeItemsStatus(itemsToHighlight, itemsToDownplay);
    }

    function selectDamageByYear() {
      let damageYear = this.dataset.year;

      let itemsToDownplay = svg.selectAll(`image:not([data-year="${damageYear}"])`);
      let itemsToHighlight = svg.selectAll(`image[data-year="${damageYear}"]`);

      if (d3.select(this).classed('is-active')) {
        itemsToHighlight = svg.selectAll('.map-svg-item');
      }

      changeButtonStatus(this);
      countByYear(itemsToHighlight);
      changeItemsStatus(itemsToHighlight, itemsToDownplay);
    }

    function selectDamageByUnesco() {
      let itemsToHighlight;
      let itemsToDownplay = null;
  
      if (this) {
        let damageUnesco = this.dataset.damage;

        itemsToHighlight = selection.selectAll('.map-svg-item')
          .filter(d => d.properties.place === damageUnesco);

        itemsToDownplay = selection.selectAll('.map-svg-item')
          .filter(d => d.properties.place !== damageUnesco);

        if (d3.select(this).classed('is-active')) {
          itemsToHighlight = svg.selectAll('.map-svg-item');
        }

        changeButtonStatus(this);
      } else {
        itemsToHighlight = svg.selectAll('.map-svg-item'); 
      }

      countByYear(itemsToHighlight);

      if (itemsToDownplay)
        changeItemsStatus(itemsToHighlight, itemsToDownplay);
    }

    function changeButtonStatus(elem) {
      if (d3.select(elem).classed('is-active')) {
        damageSelectorsByType
          .classed('is-active', false);

        damageSelectorsByYear
          .classed('is-active', false);

        damageSelectorsByUnesco
          .classed('is-active', false);

      } else {
        damageSelectorsByType
          .classed('is-active', false);

        damageSelectorsByYear
          .classed('is-active', false);

        damageSelectorsByUnesco
          .classed('is-active', false);

        d3.select(elem)
          .classed('is-active', true);
      }
    }

    function changeItemsStatus(itemsToHighlight, itemsToDownplay) {
      let re = /(icon-)(.*)(.svg)/g;
      let downplayedRe = /-white/g;

      itemsToHighlight.each(function () {
        let point = d3.select(this);

        let previousImage = point.attr('href');
        let currentImage = previousImage.replace(downplayedRe, '');

        let isDownplayed = previousImage.match(downplayedRe);

        if (isDownplayed) {
          point
            .attr('data-sel', true)
            .transition()
            .style('opacity', '.7')
            .attr('href', currentImage)
            .transition()
            .style('opacity', '1');
        }
      });

      itemsToDownplay.each(function () {
        let point = d3.select(this);

        let previousImage = point.attr('href');
        let currentImage = previousImage.replace(re, '$1$2-white$3');

        let isDownplayed = previousImage.match(downplayedRe);

        if (!isDownplayed) {
          point
            .attr('data-sel', false)
            .attr('href', currentImage);
        }
      });
    }

    function countByYear(itemsToHighlight) {
      let countByYear = d3.nest()
        .key(d => d.__data__.properties.year)
        .entries(itemsToHighlight.nodes());

      let counts = {};

      counts.count20112014 = countByYear.filter(d => d.key === '20112014');
      counts.count2014 = countByYear.filter(d => +d.key === 2014);
      counts.count2015 = countByYear.filter(d => +d.key === 2015);
      counts.count2016 = countByYear.filter(d => +d.key === 2016);
      counts.count2017 = countByYear.filter(d => +d.key === 2017);

      Object.keys(counts).map(function (objectKey) {
        if (!counts[objectKey][0]) {
          counts[objectKey][0] = { values: [] };
        }
      });

      let container = selection.select('[data-el="casualties-values"]');

      container
        .select('[data-year="2014"]')
        .text(counts.count20112014[0].values.length + counts.count2014[0].values.length);

      container
        .select('[data-year="2015"]')
        .text(counts.count2015[0].values.length);

      container
        .select('[data-year="2016"]')
        .text(counts.count2016[0].values.length);

      container
        .select('[data-year="2017"]')
        .text(counts.count2017[0].values.length);
    }
  }

  function drawMap(selection, svg, width, height, syria, damages) {

    let mapCenter = d3.geoCentroid(syria, damages);

    let mercatorProjection = d3.geoMercator()
      .center(mapCenter)
      .translate([width / 2, height / 2])
      .fitSize([width, height], syria);

    let path = d3.geoPath()
      .projection(mercatorProjection);

    path = path.projection(mercatorProjection);

    svg
      .append('image')
      .attr('xlink:href', '/syria/assets/images/syria-map-mercator.svg')
      .attr('id', 'damage-syria-terrain');

    svg.selectAll('path')
      .data(syria.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('id', 'damages-syria-path')
      .attr('class', 'map-svg__syria')
      .style('fill', 'none')
      .style('stroke-width', '0')
      .style('stroke', '#fff');

    svg.selectAll('image')
      .data(damages.features)
      .enter()
      .append('image')
      .attr('class', 'map-svg-item')
      .attr('xlink:href', d => {
        let path;

        if (d.properties.fake === true) {
          path = '/syria/assets/images/icon-unesco.svg';
        } else {
          path = '/syria/assets/images/icon-others.svg';
        }

        return path;
      })
      .attr('x', d => mercatorProjection([d.geometry.coordinates[0], d.geometry.coordinates[1]])[0])
      .attr('y', d => mercatorProjection([d.geometry.coordinates[0], d.geometry.coordinates[1]])[1])
      .attr('width', 16)
      .attr('height', 16)
      // .attr('filter', 'url(#dropshadow)')
      .attr('data-sel', true)
      .attr('data-year', d => {
        return d.properties.year !== '20112014' ? d.properties.year : 2014;
      })
      .attr('data-type', d => slug(d.properties.type))
      .attr('data-index', (d, i) => i + 1)
      .attr('data-unesco', (d) => d.properties.unesco === true ? true : false)
      .attr('data-fake', (d) => d.properties.fake === true ? true : false)
      .on('mouseenter', function () {
        if (this.getAttribute('data-sel') === 'true') {
          itemClick(selection, this, syria, damages);
        } else {
          itemClick(selection, false, syria, damages);
        }
      });

    selection
      .select('#damage-syria-terrain')
      .attr('width', () => {
        return selection.select('#damages-syria-path').node().getBBox().width;
      })
      .attr('height', () => {
        return selection.select('#damages-syria-path').node().getBBox().height;
      })
      .attr('x', () => {
        return selection.select('#damages-syria-path').node().getBBox().x;
      })

  }

  function itemClick(selection, el, syria, damages) {
    let data;

    if (el) {
      data = el.__data__.properties;

      selection.select('[data-el="info-container"]')
        .html(`
          <div class="map-info-name">${data.name}</div>
          <div class="map-info-type">${data.type}</div>
          <div class="map-info-place">${data.place}</div></div>
          <div class="map-info-coordinates">${data.coordinates}</div>
        `);

      updateInfoMap(selection, el, syria, damages);
    } else {
      selection.select('[data-el="info-container"]')
        .html('');

      updateInfoMap(selection, el, syria, damages);
    }
  }

  function drawInfoMap(selection, syria) {
    let height = parseInt(selection.select('#info-svg-map').style('padding-bottom'));
    let width = height;

    let mapCenter = d3.geoCentroid(syria);

    let mercatorProjection = d3.geoMercator()
      .center(mapCenter)
      .translate([width / 2, height / 2])
      .fitSize([width, height], syria);

    let path = d3.geoPath()
      .projection(mercatorProjection);


    selection
      .select('#info-svg-map')
      .append('image')
      .attr('xlink:href', '/syria/assets/images/syria-map-mercator.svg')
      .attr('id', 'damage-syria-terrain-info');

    selection.select('#info-svg-map')
      .selectAll('path')
      .data(syria.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('class', 'map-svg__syria')
      .attr('id', 'damages-syria-path-info')
      .style('fill', 'none')
      .style('stroke-width', '0')
      .style('stroke', '#fff');

    selection
      .select('#info-svg-map')
      .append('g')
      .attr('id', 'info-map-point-container');

    selection
      .select('#damage-syria-terrain-info')
      .attr('width', () => {
        return selection.select('#damages-syria-path-info').node().getBBox().width;
      })
      .attr('height', () => {
        return selection.select('#damages-syria-path-info').node().getBBox().height;
      })
      .attr('x', () => {
        return selection.select('#damages-syria-path-info').node().getBBox().x;
      })
      .attr('y', () => {
        return selection.select('#damages-syria-path-info').node().getBBox().y;
      })
  }

  function updateInfoMap(selection, el, syria, damages) {
    if (el) {
      let data = el.__data__.properties;

      let height = parseInt(selection.select('#info-svg-map').style('padding-bottom'));
      let width = height;

      let mapCenter = d3.geoCentroid(syria);

      let mercatorProjection = d3.geoMercator()
        .center(mapCenter)
        .translate([width / 2, height / 2])
        .fitSize([width, height], syria);

      selection.select('#info-svg-map')
        .selectAll('.info-svg__item')
        .remove();

      selection.select('#info-svg-map')
        .select('#info-map-point-container')
        .selectAll('image')
        .data(damages.features.filter(d => d.properties.id === data.id))
        .enter()
        .append('image')
        .attr('class', 'info-svg__item')
        .attr('xlink:href', d => `/syria/assets/images/icon-${slug(d.properties.type)}.svg`)
        .attr('x', d => mercatorProjection([d.geometry.coordinates[0], d.geometry.coordinates[1]])[0])
        .attr('y', d => mercatorProjection([d.geometry.coordinates[0], d.geometry.coordinates[1]])[1])
        .attr('width', 16)
        .attr('height', 16);
    } else {
      selection.select('#info-svg-map')
        .selectAll('.info-svg__item')
        .remove();
    }
  }

  function moveToGrid(svg, year, gridOptions) {

    let gridDefaults = {
      offset: { x: 0, y: 0 },
      pointWidth: 5,
      gridWidth: 60,
      gutter: 13
    };

    let actualOptions = Object.assign({}, gridDefaults, gridOptions);

    let points = svg.selectAll(`image[data-year="${year}"]`);
    let gridPoints = getGridPoints(points, actualOptions);

    points.nodes().forEach(function (p, i) {
      animatePoints(d3.select(p), gridPoints[i]);

      setTimeout(() => {
        d3.select(p).attr('xlink:href', `/syria/assets/images/icon-${p.getAttribute('data-type')}.svg`);
      }, 750);
    });
  }

  function moveToMap(svg, initialPoints) {
    let points = svg.selectAll('image[data-year]');

    points.nodes().forEach(function (p, i) {
      let path;

      animatePoints(d3.select(p), initialPoints[i]);

      setTimeout(() => {
        if (p.getAttribute('data-fake') === 'true') {
          path = '/syria/assets/images/icon-unesco.svg';
        } else {
          path = '/syria/assets/images/icon-others.svg';
        }
        d3.select(p).attr('xlink:href', path);
      }, 750);

    });
  }

  function getInitialPoints(selection) {
    const points = selection.nodes().map((point) => {
      let x = +point.getAttribute('x');
      let y = +point.getAttribute('y');
      return [x, y];
    });

    return points;
  }

  function getGridPoints(selection, options) {
    const pointHeight = options.pointWidth;
    const pointsPerRow = Math.floor(options.gridWidth / options.pointWidth);

    const points = selection.nodes().map((point, i) => {
      // const x =  options.offset.x - (options.pointWidth + options.gutter) * (i % pointsPerRow);
      const x = (options.pointWidth + options.gutter) * (i % pointsPerRow) + options.offset.x;
      const y = options.offset.y - (pointHeight + options.gutter) * Math.floor(i / pointsPerRow);
      return [x, y];
    });

    return points;
  }

  function animatePoints(point, position, duration = 1.5) {
    TweenMax.to(point.node(), duration, { attr: { x: position[0], y: position[1] }, ease: Power4.easeInOut });
  }

  function slug(string) {
    if (typeof string === 'string')
      return string.replace(' ', '-').toLowerCase();
  }

</script>
