import * as d3 from 'd3';
import _ from 'lodash';

import ScrollMagic from 'scrollMagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'gsap/src/uncompressed/plugins/ScrollToPlugin';

let controller = new ScrollMagic.Controller();
let slidesNo = 18;

function router(callback) {
  let duration = document.getElementById('home').offsetHeight;
  let slideFired = 0;

  var slideScale = d3.scaleQuantize()
    .domain([0, 1])
    .range(d3.range(slidesNo));

  function createScene(duration, slideScale) {
    return new ScrollMagic.Scene({
      duration: duration,
      triggerHook: 1,
      offset: 0
    })
      .on('progress', function (e) {
        let progress = e.progress;
        let direction = e.scrollDirection;
        let slide = slideScale(progress) + 1;

        d3.range(slidesNo).forEach((s) => {
          if (slide === s + 1 && slideFired !== s + 1) {
            callback(slide, direction);
            slideFired = s + 1;
          }
        });
      });
  }

  let scene = createScene(duration, slideScale);
  controller.addScene(scene);

  window.addEventListener('resize', _.throttle(function () {
    let duration = document.getElementById('home').offsetHeight;
    let scene = createScene(duration, slideScale);

    scene.destroy(true);
    scene.update();

    controller.addScene(scene);
  }, 800));

}

function scroller(slide) {
  let slideHeight = window.innerHeight;
  let offset = 100;
  let px = slideHeight * slide - offset;

  controller.scrollTo(px);
}

export { scroller, router };
