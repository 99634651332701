import TweenMax from 'gsap';

let previousEl;

export default el => {

  if (previousEl !== el) {
    TweenMax.to(`#slides > :not(${el})`, 0, { autoAlpha: 0, zIndex: -1 });
    TweenMax.to(el, 0.5, { autoAlpha: 1, zIndex: 1 });
  }

  previousEl = el;
};
