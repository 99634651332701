<div id="cover-{{slide}}" class="slide" style="background-image:url('{{content.mobile.cover[slide].img_url}}')">
  <!-- <h1 class="title">{{content.desktop.landing.title}}</h1> -->
  <h2 class="subtitle">{{content.desktop.landing.sections[slide].title}}</h2>
</div>

<style>

  .slide {
    min-height: 100vh;
  }

  .title {
    text-transform: uppercase;
    letter-spacing: 5px;
    width: 100%;
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    margin: 0;
  }

  .subtitle {
    width: 70%;
    font-weight: 800;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.4em;
  }
</style>
