<div id="damages-mobile" class="slide">
  <h2 class="subtitle">{{content.damages.filter_type_title}}</h2>
  <ul class="list">
  {{#each content.damages.filter_type as type}}
    <li>
      <img class="icon" src="/syria/assets/images/icon-{{type.name}}.svg">
      <p class="text">{{type.label}}</p>
    </li>
  {{/each}}
  </ul>
</div>

<style>
  .slide {
    background-image: url('../../assets/images/mob-bg-01.jpg');
  }

  .subtitle {
    width: 70%;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .list li {
    padding: 0;
    display: flex;
  }

  .icon {
    margin-right: 10px
  }

  .text {
    margin: 0;
    font-family: Oswald;
    letter-spacing: .1em;
    font-weight: 300;
  }

</style>
