<div id="landing-mobile" class="slide">
  
  <a class="about" on:click="toggleCredits()">{{content.credits.title}}</a>

  <div class="titles">
    <div class="title">{{{marked(content.landing.title)}}}</div>
    <div class="subtitle">{{content.landing.subtitle}}</div>
  </div>

  <button class="lang-buttons" on:click="switchLang()">
    <p class="{{(lang === 'it') ? 'selected' : ''}}">IT</p>
    <p>&nbsp;/&nbsp;</p>
    <p class="{{(lang === 'en') ? 'selected' : ''}}">EN</p>
  </button>

  <div class="buttons">
    {{#each content.landing.sections as section, x}}
    <div class="button button-{{x}}" on:click="scrollTo(x)">
      <h3 class="button-title">{{section.title}}</h3>
    </div>
    {{/each}}
  </div>
  <div class="credits-wrapper" style="display: {{ credits ? 'block' : 'none' }}">
    <CreditsMobile content="{{content}}" fromLanding={{true}} on:backToLanding="toggleCredits()"/>
  </div>
</div>

<style>
  .slide {
    background-image: url('../../assets/images/mob-bg-00-splitted.jpg');
    height: calc(100vh - 2rem);
  }

  .about {
    font-family: Oswald;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: underline;
    margin: 1rem 0;
    font-size: 1.2em;
  }

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title p {
    border-top: 1px solid var(--yellow-white);
    border-bottom: 1px solid var(--yellow-white);
    font-family: var(--serif);
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 7px;
    padding: .75rem 0;
    margin: .5rem 0;
  }

  .subtitle {
    font-family: var(--serif);
    font-size: 1.4rem;
    text-transform: uppercase;
    margin: .5rem 0;
    letter-spacing: 1px;
  }

  .lang-buttons {
    display: flex;

  }

  .lang-buttons p {
    color: var(--yellow-white);
    font-family: var(--sans);
    font-weight: 200;
    margin: 0;
    font-size: 1.5em;
  }

  .lang-buttons .selected {
    text-decoration: underline;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 5vh;
  }

  .button {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .button:first-of-type {
    margin-right: 3%;
  }

  .button:nth-of-type(2) {
    margin-left: 3%;
  }

  .button::before {
    content: '';
    display: block;
    width: 100%;
    height: 135px;
    overflow: visible;
    background-repeat: no-repeat;
  }

  .button-title {
    padding-top: .75rem;
    border-top: 1px solid #E2E6D5;
    text-align: center;
    font-size: 1.1em;
  }

  .button:hover .button-title {
    border-color: transparent;
  }

  .button-0::before {
    background-image: url("../../assets/images/button-political.svg");
    background-position: 50% 50%;
    background-position: center center;
    background-size: 150px;
  }

  .button-1::before {
    background-image: url("../../assets/images/button-damages.svg");
    background-position: 50% 50%;
    background-position: center center;
    background-size: 105px;
  }

  .button-0:hover::before {
    background-image: url("../../assets/images/button-political-sel.svg");
  }

  .button-1:hover::before {
    background-image: url("../../assets/images/button-damages-sel.svg");
  }

  .credits-wrapper {
    position: fixed;
    left: 0;
    top:0;
  }
</style>

<script>
  import marked from 'marked';
  import TweenMax from 'gsap';
  import 'gsap/src/uncompressed/plugins/ScrollToPlugin.js';

  // custom components
  import CreditsMobile from './CreditsMobile.html';

  export default {
    components: {
      CreditsMobile
    },
    helpers: {
      marked
    },
    methods: {
      scrollTo(x) {
        TweenMax.to(window, 0, {scrollTo: `#cover-${x}` });
      },
      switchLang() {
        let langPrev = this.get('selectedLang');
        if (langPrev === 'it') {
          this.set({ selectedLang : 'en'});
        } else if (langPrev === 'en') {
          this.set({ selectedLang : 'it'});
        }
      },
      toggleCredits() {
        this.set({'credits': !this.get('credits')});
      }
    },
    computed: {
      selectedLang: lang => lang
    },
    data() {
      return {
        credits: false
      }
    }
  }

</script>
