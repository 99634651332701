<div id="home">
  <div id="slides" class="slides">
    <Landing content={{content}} bind:selectedLang="selectedLang" lang="{{lang}}" />
    <Cover content={{content}} slide="{{slideNo}}" />
    <MapPolitical content="{{content}}" lang="{{lang}}" on:soundChange="toggleAudio(event.state)" />
    <Quote content="{{content}}" />
    <MapDamages content="{{content}}" lang="{{lang}}" />
    <TextWall content="{{content}}" />
    <Credits content="{{content}}" about="false"/>
  </div>
</div>

<style>
  #home {
    height: calc(var(--no-of-slides) * 100vh);
    overflow: hidden;
  }

  .slides {
    height: 100vh;
  }

  .slide {
    height: calc(100vh - 3rem);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; 
    overflow: hidden;

    width: calc(100% - 6rem);
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position: 50%;
  }
</style>

<script>
  import * as d3 from 'd3';
  import _ from 'lodash';
  import page from 'page';
  import ScrollMagic from 'scrollmagic';
  import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
  // import MobileDetect from 'mobile-detect';

  // Lib
  import { router } from '../js/lib/scroll-router';
  import toggleSection from '../js/lib/slide-toggler';

  // Components
  import Landing from './Landing.html';
  import Cover from './Cover.html';
  import MapPolitical from './MapPolitical.html';
  import Quote from './Quote.html';
  import MapDamages from './MapDamages.html';
  import TextWall from './TextWall.html';
  import Credits from './Credits.html';

  export default {
    oncreate() {
      this.observe('selectedLang', (selectedLang) => {
        
        if ( selectedLang && selectedLang !== this.get('lang') ) {
          location.search = `lang=${selectedLang}`;
        }
      });

      this.bgSound = new Howl({
        src: ['assets/sounds/background.mp3'],
        autoplay: true,
        loop: true,
        volume: 1
      });

      let content = this.get('content')['desktop'];

      // Pin slides to home container
      pinSlides();

      // Main scroll router
      router(slide => {
        switch (slide) {
          case 1:
            toggleSection('#landing');
            break;
          case 2:
            toggleSection('#cover');
            // this.toggleAudio(true);
            this.set({ slideNo: 0 });
            break;
          // 3-9
          case 3:
            toggleSection('#map-political');
            // this.toggleAudio(false);
            break;
          case 9:
            toggleSection('#map-political');
            // this.toggleAudio(false);
            break;
          case 10:
            toggleSection('#cover');
            // this.toggleAudio(true);
            this.set({ slideNo: 1 });
            break;
          case 11:
            toggleSection('#quote');
            break;
          // 12-14
          case 12:
            toggleSection('#map-damages');
            // this.toggleAudio(false);
            break;
          case 14:
            toggleSection('#map-damages');
            // this.toggleAudio(false);
            break;
          // 15-17
          case 15:
            toggleSection('#text-wall');
            // this.toggleAudio(true);
            break;
          case 17:
            toggleSection('#text-wall');
            break;
          case 18:
            toggleSection('#credits');
            break;
        }
      });

    },
    ondestroy() {
    },
    components: {
      Landing,
      Cover,
      Quote,
      MapPolitical,
      TextWall,
      MapDamages,
      Credits
    },
    methods: {
      toggleAudio (state) {
        if (!state) {
          if (this.bgSound.volume() !== 0)
            this.bgSound.fade(1, 0, 500);
        } else {
          if (this.bgSound.volume() === 0)
            this.bgSound.fade(0, 1, 500);
        }
      }
    },
    data() {
      return {
        slideNo: 0
      };
    }
  };

  function pinSlides() {
    let controller = new ScrollMagic.Controller();
    let duration = document.getElementById('home').offsetHeight;

    let scene = createScene(duration);
    controller.addScene(scene);

    window.addEventListener('resize', _.throttle(function() {
      scene.destroy(true);
      scene = createScene(duration);
      scene.update();

      controller.addScene(scene);
    }, 800));

    function createScene(duration) {
      return new ScrollMagic.Scene({
        duration: duration,
        triggerHook: 0.99,
        offset: 0,
        reverse: true
      })
        //.addIndicators()
        .setPin('#slides', { pushFollowers: false });
    }

    return controller;
  }

</script>
