<div id="text-wall" class="slide">
  <div class="text">
    {{{ marked(textWallSlide) }}}
  </div>
</div>

<style>
  .slide {
    background-image: url(../../assets/images/bg-02.jpg);
  }

  .text {
    background-color: rgba(93, 93, 93, .8);
    column-count: 2;
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
    font-family: var(--sans);
    font-weight: 200;
    line-height: 1.5;
    font-size: 1.2rem;
    max-height: 80vh;
    letter-spacing: .1rem;
  }

  .text > p {
    margin-top: 0;
  } 

  .text h2 {
    font-family: var(--serif);
    margin-top: 0;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  @media (max-height: 920px) {
    .text {
      width: calc(100% - 4rem);
      font-size: 0.95rem;
    }
  }
/*
  @media (max-width: 1100px) {
    .text {
      width: calc(100% - 4rem);
      font-size: .9rem;
    }
  }*/
</style>

<script>
  import marked from 'marked';
  import { router } from '../js/lib/scroll-router';

  export default {
    helpers: {
      marked
    },
    oncreate() {
      let textWallContent = this.get('content').textwall;

      router(slide => {
        switch (slide) {
          case 15:
            this.set({ textWallSlide: textWallContent[0] });
            break;
          case 16:
            this.set({ textWallSlide: textWallContent[1] });
            break;
          case 17:
            this.set({ textWallSlide: textWallContent[2] });
            break;
        }
      });

    },
    computed: {
      textWallSlide: content => content.textwall[0]
    }
  }

</script>
