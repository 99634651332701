<div id="quote" class="slide">
  <div class="text">
    {{{marked(content.quote)}}}
  </div>
</div>

<style>
  .slide {
    background-image: url(../../assets/images/mob-bg-03.jpg);
  }

  .text {
    font-size: 1rem;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    font-weight: 200;
    line-height: 1.5;
  }
</style>

<script>
  import marked from 'marked';

  export default {
    helpers: {
      marked
    }
  }
</script>
