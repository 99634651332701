<div id="landing" class="slide">
  <div class="titles">
    <div class="title">{{{marked(content.landing.title)}}}</div>
    <div class="subtitle">{{content.landing.subtitle}}</div>
  </div>

  <a class="about" on:click="goToCredits(credits)">{{content.credits.title}}</a>

  <!-- <div class="helper-audio">{{content.landing.helpers.audio}}</div> -->

  <div class="lang-buttons" on:click="switchLang()">
    <p class="{{(lang === 'it') ? 'selected' : ''}}">IT</p>
    <p>&nbsp;/&nbsp;</p>
    <p class="{{(lang === 'en') ? 'selected' : ''}}">EN</p>
  </div>

  <div class="buttons">
    {{#each content.landing.sections as section, x}}
    <div class="button button-{{x}}" on:click="goToSection(x + 1)">
      {{#if x === 0}}
        <img class="img-default" src="/syria/assets/images/button-political.svg">
        <img class="img-hover" src="/syria/assets/images/button-political-sel.svg">
      {{/if}}
       {{#if x === 1}}
        <img class="img-default" src="/syria/assets/images/button-damages.svg">
        <img class="img-hover" src="/syria/assets/images/button-damages-sel.svg">
      {{/if}}
      <h3 class="button-title">{{section.title}}</h3>
    </div>
    {{/each}}
  </div>

  <div class="helper-scroll">
    <div id="mouse">
      <div></div>
    </div>
  </div>
</div>

<style>
  .slide {
    background-image: url('/syria/assets/images/bg-01-splitted.jpg');
    justify-content: space-around;
  }

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title p {
    border-top: 1px solid var(--yellow-white);
    border-bottom: 1px solid var(--yellow-white);
    font-family: var(--serif);
    font-size: 3.2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 7px;
    padding: .75rem 0;
    margin: .5rem 0;
  }

  .subtitle {
    font-family: var(--serif);
    font-size: 1.4rem;
    text-transform: uppercase;
    margin: .5rem 0;
    letter-spacing: 1px;
  }

  .about {
    font-family: Oswald;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: underline;
  }

  .about:hover {
    text-decoration: none;
  }
  
  .lang-buttons {
    display: flex;
    cursor: pointer;
  }

  .lang-buttons p {
    color: var(--yellow-white);
    font-family: var(--sans);
    font-weight: 200;
    margin: 0;
    font-size: 1.5em;
  }

  .lang-buttons .selected {
    text-decoration: underline;
  }

  .helper-scroll {
    font-size: 1rem;
    font-family: var(--sans);
    font-weight: 200;
    width: 10%;
    text-align: center;
  }

  .helper-scroll {
    margin: 1rem 0;
    text-transform: uppercase;
    position: absolute;
    left: 45%;
    bottom: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .helper-scroll::after {
    content: '';
    display: block;
    height: 100%;
    overflow: visible;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-position: center center;
    background-size: 32px;
    height: 60px;
    background-image: url("../../assets/images/scroll.svg");
    width: 100%;
  } */

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 5vh;
  }

  .button {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .button:first-of-type {
    margin-right: 3%;
  }

  .button:nth-of-type(2) {
    margin-left: 3%;
  }

  .button .img-hover {
    display: none;
  }

  .button-title {
    text-align: center;
    padding-bottom: .75rem;
    border-bottom: 1px solid var(--yellow-white);
  }

  .button:hover .button-title {
    border-color: transparent;
  }

  .button-0:hover .img-default{
    display: none;
  }

  .button-0:hover .img-hover{
    display: block;
  }

  .button-1:hover .img-default{
    display: none;
  }

  .button-1:hover .img-hover{
    display: block;
  }   

  #mouse {
    /*fine del posizionamento*/
    
    border:2px solid #E2E6D5;
    border-radius: 25px;
    width:40px;
    height:60px;
    transform: rotate(0deg) scale(.8);
  }


  #mouse div {
    border-radius:3px;
    background-color:#E2E6D5;
    margin-top:15px;
    margin-left:auto;
    margin-right:auto;
    width:6px;
    height:6px;
    animation-name:wheel;
    animation-duration:1.8s;
    animation-iteration-count: infinite;
  }

  @keyframes wheel{
    0%   {margin-top:5px; height:6px;}
    80% {margin-top:25px; height:6px; width:6px;}
    90% {height:10px; width:4px;}
    100% {margin-top:5px; height:6px; width:6px;}
  }

  @media (max-width: 1200px) {
    .button-title {
      border: none;
      text-decoration: underline;
    }
  }
</style>

<script>
  import marked from 'marked';
  import { router, scroller } from '../js/lib/scroll-router';

  // custom components
  import Credits from './Credits.html';

  export default {
    helpers: {
      marked
    },
    methods: {
      goToCredits(slide) {
        scroller(slide);
      },
      goToSection(no) {
        let slide = no === 1 ? 3 : 12;
        scroller(slide);
      },
      switchLang() {
        let langPrev = this.get('selectedLang');
        if (langPrev === 'it') {
          this.set({ selectedLang : 'en'});
        } else if (langPrev === 'en') {
          this.set({ selectedLang : 'it'});
        }
      }
    },
    computed: {
      selectedLang: lang => lang
    },
    components: {
      Credits
    },
    data() {
      return {
        credits: 18
      }
    }
  }

</script>
