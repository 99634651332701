<div id="home-mobile">
  <LandingMobile content={{content.desktop}} bind:selectedLang="selectedLang" lang="{{lang}}" />
  <CoverMobile content={{content}} slide='0' />
  <MapPoliticalMobile content={{content}} lang="{{lang}}"/>
  <CoverMobile content={{content}} slide='1' />
  <QuoteMobile content={{content.desktop}} />
  <MapDamagesLegendMobile content={{content.desktop}} />
  <MapDamagesMobile content={{content}} lang="{{lang}}" />
  <TextWallMobile content="{{content}}" />
  <CreditsMobile content="{{content.desktop}}" fromLanding={{false}} />
</div>

<style>
  #home-mobile {
    max-width: 600px;
    margin: 0 auto;
  }

  .slide {
    width: calc(100% - 2rem);
    min-height: calc(100vh - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-position: 50% 50%;
  }
</style>

<script>
  // Components
  import LandingMobile from './LandingMobile.html';
  import CoverMobile from './CoverMobile.html';
  import MapPoliticalMobile from './MapPoliticalMobile.html';
  import QuoteMobile from './QuoteMobile.html';
  import MapDamagesLegendMobile from './MapDamagesLegendMobile.html';
  import MapDamagesMobile from './MapDamagesMobile.html';
  import TextWallMobile from './TextWallMobile.html';
  import CreditsMobile from './CreditsMobile.html';

  export default {
    components: {
      LandingMobile,
      CoverMobile,
      MapPoliticalMobile,
      QuoteMobile,
      MapDamagesLegendMobile,
      MapDamagesMobile,
      TextWallMobile,
      CreditsMobile
    },
    oncreate() {
      this.observe('selectedLang', (selectedLang) => {
        if ( selectedLang && selectedLang !== this.get('lang') ) {
          location.search = `lang=${selectedLang}`;
        }
      });
    },
    ondestroy() {
    }
  }
</script>
