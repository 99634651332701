<div id="text-wall-mobile" class="slide">
  <div id="text">
    <p>{{{marked(content.desktop.textwall[0])}}}</p>
    <div id="text-slide">
    <div id="text-overlay"></div>
    {{#each content.desktop.textwall as text, index}}
      {{#if index !== 0 }}
        <p>{{{marked(text)}}}</p>
      {{/if}}
    {{/each}}  
    </div>
  </div>

  <button id="button" class="button" on:click="toggleText()">{{content.mobile.textwall.button}}</button>
</div>

<style>
  #text {
    position: relative;
    height: 60vh;
    overflow: hidden;
    transition: all .5s ease;
  }

  #text p {
    background-color: #3F3F3F;
    padding: 4%;
  }

  #text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(93,93,93,0) 0%,rgba(93,93,93,1) 96%);
    transition: all .5s ease;
  }

  .text-visible {
    height: auto !important;
  }

  .button {
    padding: 20px;
    font-family: Oswald;
    text-transform: uppercase;
    color: #E2E6D5;
    text-decoration: underline;
    font-size: 1.2em;
  }

  

</style>

<script>
  import marked from 'marked';

  export default {
    helpers: {
      marked
    },
    data() {
      return {
        textState: false
      }
    },
    methods: {
      toggleText() {
        this.set({ textState: !this.get('textState') });
        document.getElementById('button').style.display = 'none';
      }
    },
    oncreate() {
      let component = this;

      component.observe('textState', event => {
        let text = document.getElementById('text');
        let textOverlay = document.getElementById('text-overlay');

        if (event) {
          text.style.height = '100vh';
          textOverlay.style.opacity = 0;

          setTimeout(function() {
            text.className = 'text-visible';
          }, 500)
        }
      })
    }
  }

</script>
