<div id="map-political-mobile" class="slide">
  <header>{{content.mobile.political.disclaimer}}</header>
  <div id="carousel-mobile">
    {{#each content.desktop.political as slide, index}}
      <div class="carousel-cell">
        <div class="year">{{slide.year}}</div>
        <img class="map" src="/syria/assets/images/mob-map-it-201{{index + 1}}.svg" style="display: {{textState ? 'none' : 'block'}};">
        <!-- <div class="text-overlay"></div> -->
        <div class="text" on:click='toggleText()' style="display: {{textState ? 'block' : 'none'}}; height: {{mapHeight}} ">
          <p>{{{marked(slide.text[0])}}}</p>
        </div>
      </div>
    {{/each}}
  </div>

  <div class="info-container">
  <button class="button" on:click='toggleText()'>{{textState ? content.mobile.political.button_close : content.mobile.political.button}}</button>

    <div class="legend-container">
      <!-- <h4 class="title">{{content.desktop.political_legends.title}}</h4> -->
      <ul class="legends">
        {{#each content.desktop.political_legends.legends as legend, index}}
        <li class="legend">
          <img class="image" src="/syria/assets/images/map-political-legend-0{{index+1}}.svg">
          <div class="label">{{legend.label}}</div>
        </li>
        {{/each}}
      </ul>
    </div>
  </div>

  <!-- <button class="button button-close" on:click='toggleText()' style="display: {{textState ? 'block' : 'none'}}"></button> -->

  <AudioPlayerMobile src="/syria/assets/sounds/political-voice-{{lang}}.aac" slide="{{slide}}" lang="{{lang}}" content="{{content}}" useSprites="true" />
</div>

<style>
  .slide {
    justify-content: flex-start;
    position: relative;
  }
  
  header {
    font-family: Oswald;
    font-weight: 300;
    font-size: 1em;
  }

  #carousel-mobile {
    width: inherit;
    position: relative;
    margin-top: 1.5vh;
  }

  .flickity-viewport {
   /* overflow: visible;*/
  }

  .flickity-prev-next-button {
    top: 22px;
    background: none;
  }

  .flickity-prev-next-button .arrow {
    fill: #E2E6D5;
  }

  .carousel-cell {
    width: 100%;
    /* height: 70vh; */
  }
  
  .year {
    width: 100%;
    /* position: absolute;
    top: 0; */
    text-align: center;
    height: 44px;
    line-height: 44px;
  }

  .map {
    width: 100%;
    /* padding-bottom: 80%; */
    /* position: absolute;
    top: 11%; */
  }

  .button {
    padding: 20px;
    font-family: Oswald;
    text-transform: uppercase;
    color: #E2E6D5;
    text-decoration: underline;
    font-size: 1.2em;
  }

  .button-close {
    display: none;
    position: absolute;
    bottom: 90px;
    width: 50px;
    height: 50px;
    padding: 0;
    left: calc(50% - 25px);

    background-repeat: no-repeat;
    background-image: url('../../assets/images/mob-close-button.svg');
    background-size: 50px 50px;
    background-position: 50%;
  }

  .text {
    display: none;
    /* height: calc(67vh - 50px); */
    /* position: absolute; */
    /* margin-top: 50px; */
    /* opacity: 0; */
    overflow-y: scroll;
    padding: 0 20px;
  }

  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 67vh;
    background: linear-gradient(to top, rgba(93, 93, 93, 1) 0%,rgba(93, 93, 93, 0) 5%);
    z-index: 1;
    pointer-events: none;
  }

  .info-container {
    width: 80%;
    /* position: absolute;
    bottom: 50px; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  /* Legend */
  .legend-container {
    width: 100%;
  }

  .legends {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 127px;
  }

  .legend {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: .75rem;
    width: 50%;
  }

  .image {
    width: 12px;
    margin-right: .5rem;
  }

  .label {
    display: inline;
    font-family: var(--sans);
    font-weight: 200;
  }
</style>
  
<script>
  //Components
  import AudioPlayerMobile from './AudioPlayerMobile.html'; 

  // Lib
  import marked from 'marked';  
  import Flickity from 'flickity-imagesloaded';

  export default {

    oncreate() {

      const carouselSettings = {
        cellAlign: 'left',
        contain: true,
        adaptiveHeight: true,
        imagesLoaded: true,
        lazyLoad: false,
        wrapAround: false,
        autoPlay: false,
        pageDots: false,
        // dragThreshold: 12,
        draggable: false
      };

      let carousel = new Flickity(document.getElementById('carousel-mobile'), carouselSettings);

      this.observe('textState', (textState) => {
        //carousel.resize();
      });

      carousel.on('select', () => {
        this.set({ slide: carousel.selectedIndex });
      });
    },
    data() {
      return {
        textState: false,
        slide: 0,
        mapHeight: 0
      }
    },
    helpers: {
      marked
    },
    methods: {
      toggleText() {
        this.set({ 
          textState : !this.get('textState'),
          mapHeight: `${document.getElementById('carousel-mobile').getBoundingClientRect().height}px`
        })
      }
    },
    components: {
      AudioPlayerMobile
    }
  }

</script>
