import axios from 'axios/dist/axios';
import queryString from 'query-string';
import yaml from 'js-yaml';
import 'babel-polyfill';

// Config
import './config/analytics.config';

// Components
import Home from '../components/Home.html';
import HomeMobile from '../components/HomeMobile.html';

// Initialization
let params = queryString.parse(location.search);
let lang = params.lang ? params.lang : 'it';
let home;
let homeMobile;

axios.get(`data/content-${lang}.yaml`)
  .then(function (response) {
    setPathname();
    renderComponents(response);

    window.addEventListener('resize', () =>  {
      setPathname();
      renderComponents(response);
    });
  });

function setPathname() {
  let viewportWidth = window.innerWidth;

  if (viewportWidth >= 1025) {
    if (window.location.pathname.indexOf('desktop') === -1) {
      window.location.pathname = '/syria/desktop.html';
    } 
  } else {
    if (window.location.pathname.indexOf('mobile') === -1) {
      window.location.pathname = '/syria/mobile.html';
    }
  }
}

function renderComponents(response) {
  if (window.location.pathname.indexOf('desktop') !== -1) {
    if (!home) {
      home = new Home({
        target: document.querySelector('#app'),
        data: {
          content: yaml.load(response.data).desktop,
          lang
        }
      });
    }
  } else if (window.location.pathname.indexOf('mobile') !== -1) {
    if (!homeMobile) {
      homeMobile = new HomeMobile({
        target: document.querySelector('#app-mobile'),
        data: {
          content: yaml.load(response.data),
          lang
        }
      });
    }
  }
}
