<div class="player">
  <p>{{content.mobile.damages_info.player}}</p>
  <button on:click="togglePlayer()">
    <img src="/syria/assets/images/mob-button-{{playing ? 'pause' : 'play'}}.svg">
  </button>
</div>

<style>
  .player {
    /* position: absolute;
    bottom: 0;
    left: 0; */
    width: calc(100% - 40px);
    height: 35px;
    padding: 20px;
    background-color: #3F3F3F;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .player p {
    margin: 0;
    font-family: Oswald;
    text-transform: uppercase;
    color: #E2E6D5;
    font-size: 1.2em;
    font-weight: 300;
  }

  button img{
    width: 40px;
  }


</style>

<script>
  import { Howl } from 'howler/dist/howler';

  export default {
    oncreate() {
      let lang = this.get('lang');
      let useSprites = this.get('useSprites');
      
      if (useSprites === 'true') {
        // Audio political - using sprites
        let sprite = this.get(`sprite_${lang}`);

        let audio = new Howl({
          src: this.get('src'),
          sprite: this.get(`sprite_${lang}`),
          html5: true
        });

        this.audio = audio;

        this.observe('slide', slide => {
          this.set({ spritePos: 2011 + slide });

          this.changeSprite()
        })

      } else {
        // Audio damages - not using sprites
        let audio = new Howl({
          src: this.get('src'),
          html5: true
        });

        this.audio = audio;
      }

    },
    methods: {
      changeSprite() {
        let sprite = this.get('spritePos');

        if (this.audio.playing(this.audioId)) {
          this.audio.pause(this.audioId)
          this.audioId = this.audio.play(sprite.toString());
        } 
      },
      togglePlayer() {
        let useSprites = this.get('useSprites');

        if (useSprites === 'true') {
          let sprite = this.get('spritePos');
          if (!this.audio.playing(this.audioId)) {
            this.audioId = this.audio.play(sprite.toString());
            this.set({ playing: true });
          } else {
            this.audio.pause(this.audioId)
            this.set({ playing: false });
          }
        } else {
          if (!this.audio.playing(this.audioId)) {
            this.audioId = this.audio.play();
            this.set({ playing: true });
          } else {
            this.audio.pause(this.audioId)
            this.set({ playing: false });
          }
        }
        
      }
    },
    data() {
      return {
        playing: false,
        sprite_it: {
          2011: [0, 32 * 1000],
          2012: [32 * 1000, 63.34 * 1000],
          2013: [63.34 * 1000, 99.27 * 1000],
          2014: [99.27 * 1000, 137.40 * 1000],
          2015: [137.40 * 1000, 168.77 * 1000],
          2016: [168.77 * 1000, 199.54 * 1000],
          2017: [199.54 * 1000, 231.7698324611896 * 1000]
        },
        sprite_en: {
          2011: [0, 72.43 * 1000],
          2012: [72.43 * 1000, 142.97 * 1000],
          2013: [142.97 * 1000, 219.5 * 1000],
          2014: [219.5 * 1000, 290.98 * 1000],
          2015: [290.98 * 1000, 362.25 * 1000],
          2016: [362.25 * 1000, 426 * 1000],
          2017: [426 * 1000, 494.8 * 1000]
        }
      }
    }
  }

</script>
