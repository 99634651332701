{{#if selectedLegend }}
  <div class="text">{{{marked(selectedLegend.text)}}}</div>
{{/if}}

<h4 class="title">{{content.political_legends.title}}</h4>
<!-- <p class="helper">{{content.political_legends.helper}}</p> -->

<ul class="legends">
  {{#each content.political_legends.legends as legend, index}}
  <li class="legend {{selectedLegend && selectedLegend.label === legend.label ? 'selected' : ''}}" on:click="select(index)">
    <img class="image" src="/syria/assets/images/map-political-legend-0{{index+1}}.svg">
    <div class="label">{{legend.label}}</div>
  </li>
  {{/each}}
</ul>

<style>

  .title {
    font-size: .825rem;
    font-family: var(--serif);
    text-transform: none;
  }

  .helper {
    margin-top: .25rem;
    font-family: var(--serif);
    font-size: .825rem;
    font-style: italic;
  }

  .text {
    margin-bottom: 1rem;
    font-family: var(--sans);
    font-weight: 200;
    letter-spacing: .05rem;
    line-height: 1.2rem;
    font-size: .925rem;
  }

  .legends {
    list-style-type: none;
    padding: 0;
  }

  .legend {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: .75rem;
  }

  .image {
    width: 16px;
    margin-right: .5rem;
  }

  .label {
    display: inline;
    font-family: var(--sans);
    font-weight: 200;
  }

  .legend.selected .label {
    border-bottom: 1px solid var(--yellow-white);
    font-weight: 400;
  }
</style>

<script>
  import marked from 'marked';

  export default {
    helpers: {
      marked
    },
    methods: {
      select(index) {
        let current = this.get('content').political_legends.legends[index];
        this.set({ selectedLegend: current });
      }
    }
  }
</script>
