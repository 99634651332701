<div id="map-damages-mobile" class="slide">
  <div class="content-container content-hidden" id="content-container">
    <div class="content">
      <div class="content-name" id="content-name"><p></p></div>
      <div class="content-type" id="content-type"><p></p></div>
      <div class="content-symbol" id="content-symbol"><img></div>
      <div class="content-place" id="content-place"><p></p></div>
      <div class="content-coordinates" id="content-coordinates"><p></p></div>
      <div class="content-text" id="content-text"><p></p></div>
    </div>
    <div class="button-close" on:click="closeContent()">
      <!-- <div class="text-overlay"></div> -->
    </div>
  </div>
  <div class="section-container" id="section-container">
    {{#each content.desktop.damages.years as section}}
    <div class="section" data-year="{{section.key}}">
      <div class="year">{{section.label}}</div>
      <div class="text"><span class="number"></span>&nbsp;{{content.desktop.damages.casualties_title}}</div>
      <div class="graph" id="graph"></div>
    </div>
    {{/each}}
  </div>
  <div class="damages-player" id="damages-player">
    <AudioPlayerMobile src="/syria/assets/sounds/damages-voice-{{lang}}.aac" lang="{{lang}}" content="{{content}}" useSprites="false"/>
  </div>
  
</div>

<style>
  .slide {
    overflow: hidden;
  }
  .section {
    font-family: Oswald;
    font-weight: 300;
    font-size: 1.3em;

    margin-bottom: 4vh;
  }

  .section-container {
    transition: opacity .5s ease;
  }

  .section-hidden {
    opacity: .5;
    filter: blur(5px);
  }

  .content-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(180,180,180,.3);
    opacity: 1;
    pointer-events: auto;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 85px;

    transition: opacity .5s ease;
  }
  
  .content-hidden {
    opacity: 0;
    pointer-events: none;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    max-height: 77vh;

    font-family: Oswald;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    font-size: 1.5em;
  }

  .content p {
    margin: .5rem 0;
  }
  
  .content-name {
    font-family: Libre Baskerville;
    text-transform: none;
    padding: 0 1rem;
  }

  .content-symbol {
    width: 40%;
  }

  .content-symbol img {
    width: 100%
  }
  
  .content-coordinates {
    font-size: .8em;
  }
  
  .content-text {
    max-height: 30vh;
    overflow-y: auto;
    font-size: .8em;
    line-height: 1.2em;
    text-transform: initial;
    padding: 0 1rem;
    width: calc(100% - 2rem);
    text-align: left;
    margin-bottom: 3vh;
  }

  .text-overlay {
    position: absolute;
    bottom: calc(50px + 3vh - 1px);
    width: 1000%;
    right: -500%;
    height: 20%;
    background: linear-gradient(to bottom, rgba(93,93,93,0) 0%,rgba(93,93,93,1) 100%);
  }

  .button-close {
    /*position: absolute;
    bottom: 50px;*/
    position: relative;

    width: 50px;
    height: 50px;

    background-repeat: no-repeat;
    background-image: url('../../assets/images/mob-close-button.svg');
    background-size: 50px 50px;
    background-position: 50%;
  }

  .text {
    text-transform: uppercase;
    margin-bottom: 2vh;
  }

  .graph {
    float: left;
  }
  
  .item-container {
    float: left;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    /*pointer-events: none;*/
  }
  
  .unesco {
    /*pointer-events: auto;
    background: radial-gradient(ellipse at center, rgba(255,248,198,.7) 40%, rgba(240,249,255,0) 70%);*/
  }

  .item {
    width: 100%;
    height: 100%;
  }

  .damages-player {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .damages-player-active {
    display: block;
  }
</style>

<script>
  import * as d3 from 'd3';
  import _ from 'lodash';
  import ScrollMagic from 'scrollmagic';
  import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
  import imagesLoaded from 'imagesloaded';

  //Components
  import AudioPlayerMobile from './AudioPlayerMobile.html'; 

  export default {
    oncreate() {
      let content = this._state.content.mobile;

      imagesLoaded('#map-damages-mobile', () => {
        pinPlayer();
      })

      d3.json('/data/syria-damages.geo.json', function(error, data) {
        if (error) throw error;

        let sections = d3.selectAll('.section');
        let slideWidth = parseInt(d3.select('#map-damages-mobile').style('width'));

        let scaleItemNumber = d3.scaleLinear()
          .domain([300, 1024])
          .range([5, 10]);

        sections.nodes().forEach(section => {
          let year = section.dataset.year;

          if ( year ===  '20112014') {
            year = 2014;
          }

          let graph = d3.select(section).select('.graph');
          let datum = data.features.filter( d => +d.properties.year === +year);
          let count = datum.length;

          let datoSel = [];

          datum.forEach(d => {
            datoSel.push(d.properties);
          });

          let categories = d3.nest()
              .key(function(d) { return d.type })
              .entries(datoSel);


          categories.forEach(category => {
            category.values.sort((a,b) => d3.descending(a.unesco, b.unesco))

            graph
            .append('div')
            .on('click', itemClick)
            .attr('class', 'cat-container')
              .selectAll('div')
              .data(category.values)
              .enter()
              .append('div')
              .attr('class', d => {
                if ( d.unesco === true) {
                  return 'item-container unesco'
                } else {
                  return 'item-container'
                }
              })
              .style('width', ( slideWidth / (parseInt(scaleItemNumber(document.getElementById('app-mobile').getBoundingClientRect().width)))) + 'px')
              .append('img')
              .attr('src', d => {
                if ( d.unesco === true) {
                  return `/syria/assets/images/icon-${slug(d.type)}.svg`
                } else {
                  return `/syria/assets/images/icon-${slug(d.type)}.svg`
                }
              })
              .attr('class', 'item');
          });


          d3.select(section)
            .select('.text')
            .select('.number')
            .text(count);
        })
      })

      function itemClick() {
        let el = d3.event.target;

        d3.select('body').style('overflow-y', 'hidden');

        d3.select('#section-container')
          .classed('section-hidden', true);

        let container = d3.select('#content-container')
          .classed('content-hidden', false);
          
        container.select('#content-name p')
          .text(el.__data__.name);

        container.select('#content-type p')
          .text(el.__data__.type);

        container.select('#content-symbol img')
          .attr('src', `/syria/assets/images/icon-${slug(el.__data__.type)}.svg`);

        container.select('#content-place p')
          .text(el.__data__.place);

        container.select('#content-coordinates p')
          .text(el.__data__.coordinates);

        container.select('#content-text p')
          .text(function() {
            let place = el.__data__.place;

            switch (place) {
              case 'Aleppo':
                return content.damages_unesco.filter(d => d.key === 'aleppo')[0].text;
                break;
              case 'Bosra':
                return content.damages_unesco.filter(d => d.key === 'bosra')[0].text;
                break;
              case 'Damascus':
                return content.damages_unesco.filter(d => d.key === 'damasco')[0].text;
                break;
              case 'Palmyra':
                return content.damages_unesco.filter(d => d.key === 'palmira')[0].text;
                break;
            }
          });

      }

      function slug(string) {
        if (typeof string === 'string')
          return string.replace(' ', '-').toLowerCase();
      }
    },
    methods: {
      closeContent() {
        d3.select('body').style('overflow-y', 'scroll');

        d3.select('#content-container')
          .classed('content-hidden', true);

        d3.select('#section-container')
          .classed('section-hidden', false);
      }
    },
    components: {
      AudioPlayerMobile
    }
  }

  function pinPlayer() {
    let duration = document.getElementById('map-damages-mobile').getBoundingClientRect().height;
    let controller = new ScrollMagic.Controller();

    let scene = createScene(duration);
    controller.addScene(scene);

    window.addEventListener('resize', _.throttle(function () {
      scene.destroy(true);
      scene = createScene(duration);
      scene.update();

      controller.addScene(scene);
    }, 800));

    function createScene(duration) {
      return new ScrollMagic.Scene({
        triggerElement: '#map-damages-mobile',
        duration: duration,
        triggerHook: 1,
        offset: 0,
        reverse: true
      })
      .setClassToggle("#damages-player", "damages-player-active")
      // .addIndicators()
    }

    return controller;
  }  

</script>
