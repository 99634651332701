<div id="credits" class="slide">
  <a class="home-link" on:click="fire('backToLanding')" style="display: {{fromLanding ? 'block' : 'none'}}">{{content.nav_tools.home}}</a>

  <h2 class="title">{{content.credits.title}}</h2>

  <div class="publisher">
    <h3>{{content.credits.publisher_title}}</h3>
    <p>{{{marked(content.credits.publisher)}}}</p>
  </div>

  <div class="people">
    <h3>{{content.credits.people_title}}</h3>
    {{#each content.credits.people as person}} {{{marked(person)}}} {{/each}}
  </div>

  <div class="sources">
    <h3>{{content.credits.sources_title}}</h3>
    <p>{{content.credits.sources}}</p>
  </div>
</div>

<style>
  .slide {
    background-image: url('../../assets/images/mob-bg-03.jpg');
  }

  p {
    margin: .7rem 0;
  }

  .home-link {
    font-family: Oswald;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: underline;
    margin: 1rem 0;
    font-size: 1.2em;
  }

  .title {
    font-size: 2rem;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin: 1rem 0;
  }

  .publisher,
  .people,
  .sources {
    text-align: center;
    font-size: 1rem;
    font-family: var(--sans);
    font-weight: 200;
    letter-spacing: 2px;
  }

  a {
    color: var(--yellow-white);
    text-decoration: underline;
  }

  h3 {
    font-weight: 200;
    font-size: 1rem;
    letter-spacing: 1px;
    margin: 1rem 0;
  }

  .publisher p {
    font-family: var(--serif);
    text-transform: uppercase;
  }

  .publisher span {
    text-transform: lowercase;
  }

  .publisher a {
    line-height: 1.5em;
  }

  .sources strong { 
    font-weight: 400;
  }
</style>

<script>
  import marked from 'marked';

  export default {
    helpers: {
      marked
    }
  }

</script>
