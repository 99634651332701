<div id="credits" class="slide">
  <a class="home-link" on:click="goToSection(home)">{{content.nav_tools.home}}</a>

  <h2 class="title">{{content.credits.title}}</h2>

  <div class="publisher">
    <h3>{{content.credits.publisher_title}}</h3>
    <p class="agency">{{{marked(content.credits.publisher)}}}</p>
  </div>

  <div class="people">
    <h3>{{content.credits.people_title}}</h3>
    {{#each content.credits.people as person}} {{{marked(person)}}} {{/each}}
  </div>

  <div class="sources">
    <h3>{{content.credits.sources_title}}</h3>
    <p>{{content.credits.sources}}</p>
  </div>
</div>

<style>
  .title {
    font-size: 2rem;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

  .home-link {
    font-family: Oswald;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: underline;
  }

  .home-link:hover {
    text-decoration: none;
  }

  .publisher,
  .people,
  .sources {
    text-align: center;
    font-size: 1rem;
    font-family: var(--sans);
    font-weight: 200;
    letter-spacing: 2px;
  }

  a {
    color: var(--yellow-white);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  h3 {
    font-weight: 200;
    font-size: 1rem;
    letter-spacing: 1px;
    margin: 1.5rem 0;
  }

  .publisher p {
    font-family: var(--serif);
    text-transform: uppercase;
  }

  .agency span {
    text-transform: lowercase;
  }

  .sources strong { 
    font-weight: 400;
  }
</style>

<script>
  import marked from 'marked';

  import { scroller } from '../js/lib/scroll-router';

  export default {
    helpers: {
      marked
    },
    methods: {
      goToSection(slide) {
        scroller(slide);
      }
    },
    data() {
      return {
        home: 1
      }
    }
  }

</script>
