<div class="nav-tools">
  <div class="chapter home-link">
    <div class="chapter-link chapter-label" on:click="scrollTo(home)">{{content.nav_tools.home}} &#8594;</div>
  </div>
  <div class="chapter">
    <div class="chapter-link" on:click="scrollTo(slide)">
      <!-- <div class="go-to">{{content.nav_tools.go_to}}</div> -->
      <div class="chapter-label">{{content.nav_tools.chapter_labels[which]}} &#8594;</div> 
    </div>
    <!-- <img class="chapter-icon-{{which}}" src="assets/images/{{which}}-map-icon.svg"> -->
  </div>
  <div class="switchers">
    <div class="sound-switch" on:click="setAudioState()">
      {{content.nav_tools.sound_label}}: <span class="sound-state">{{soundState ? 'on' : 'off' }}</span>
    </div>
    <div class="lang-switch"></div>
  </div>
</div>

<style>
  .nav-tools {
    position: absolute;
    top: 3rem;
    right: 3rem;
    padding-left: 3rem;
    width: calc(18% - 1.5rem);
  }

  .chapter {
    font-family: var(--sans);
    font-weight: 200;
    text-align: right;
    border-bottom: 1px solid var(--yellow-white);
    display: flex;
    justify-content: center;
    padding: .4rem .5rem;
  }
  
  .chapter:first-of-type {
    padding-top: 0;
  }

  .chapter-link {
    cursor: pointer;
    user-select: none;
  }

  .chapter-link:hover {
    text-decoration: underline;
  }

  .chapter-label {
    font-size: 1rem;
    text-transform: uppercase;
  }

  .chapter-icon-damages {
    height: 52px;
  }

  .chapter-icon-political {
    height: 32px;
  }

  .switchers {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--yellow-white);
  }

  .sound-switch {
    font-family: var(--sans);
    text-transform: uppercase;
    font-weight: 200;
    text-align: center;
    cursor: pointer;
    user-select: none;
    padding: .5rem .5rem;
    /* width: 50%; */
  }

  .sound-switch:hover {
    text-decoration: underline;
  }

  .sound-state {
    font-weight: 400;
  }
</style>

<script>
  import { scroller } from '../js/lib/scroll-router';

  export default {
    oncreate() {
    },
    data() {
      return {
        which: 'damages',
        slide: 12,
        home: 1,
        soundState: true
      }
    },
    methods: {
      scrollTo(slide) {
        scroller(slide);
        this.fire('goToEvent');
      },
      setAudioState() {
        this.set({ soundState: !this.get('soundState') });
        this.fire('soundChange', { state: this.get('soundState') });
      }
    }
  }

</script>
